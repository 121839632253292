
#scrollable-dropdown-menu {
  .tt-menu {
    max-height: 150px;
    overflow-y: auto;
  }
}

.open {
  > .dropdown-menu {
    padding: 15px 0 0 0;
  }
}

.dropdown-menu {
  li {
    margin-top: 3px;
  }

  li:first-child {
    margin-top: 0;
  }

  > li {
    > a {
      display: block;
      padding: 3px 25px;
      clear: both;
      font-weight: 400;
      line-height: 1.42857;
      color: $text-color;
      white-space: nowrap;
    }
  }
}

.open {
  .dropdown-toggle.btn-primary {
    background-color: $btn-primary-hover-bg;
    color: $btn-default-color;
  }
}

/* Media query */

@media screen and (min-width: 800px) {
  .menu-suboverlay-box {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    z-index: 30;
    min-height: 600px;
  }

  .dropdown-menu {
    box-shadow: none;

    li {
      margin-top: 0 !important;

      a {
        position: relative;
      }
    }

    li > a:hover, .dropdown-menu > li > a:focus {
      background-color: transparent !important;
    }
  }

  .no_scroll {
    overflow-y: hidden;
  }
  .stick_menu {
    display: none;
  }

  #menu {
    height: 50px;
    margin: 0 auto;
    position: relative;
  }

  .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: transparent !important;
  }

  #mcnav {
    .title {
      display: none;
    }

    ul {
      list-style: none;

      ul {
        padding: 10px 0;
      }
    }

    > ul {
      > li {
        width: auto;
        float: left;
        max-width: 200px;

        &.parent {
          > a:after {
            content: "\e900";
            font-family: 'icomoon' !important;
            display: flex;
            float: right;
            margin-left: 10px;
            color: #979a9d;
            font-size: 8px;
          }
        }

        > a {
          font-size: $font-size-menu-link;
          color: $font-color-menu;
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          text-align: center;
          display: block;
        }
      }
    }

    ul li ul li > [class^="icon-"], ul li ul li > [class*=" icon-"] {
      float: left;
      margin-top: 10px;
      font-size: 20px;
    }

    ul {
      li {
        > ul {
          border-top: 1px solid #e1e1e1;
          transition: .2s height linear, .1s opacity linear;
          -webkit-transition: .2s height linear, .1s opacity linear;
          -moz-transition: .2s height linear, .1s opacity linear;
          -o-transition: .2s height linear, .1s opacity linear;
        }
      }
    }

    ul {
      li {
        > ul {
          -webkit-transition: opacity 200ms ease-in-out 0s;
          -moz-transition: opacity 200ms ease-in-out 0s;
          -o-transition: opacity 200ms ease-in-out 0s;
          transition: opacity 200ms ease-in-out 0s;
        }
      }
    }
  }

  .mc-nav-user {
    position: relative;
    display: inline-block;

    .mcdropdown-content {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 200px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      z-index: 3;
      border: 2px solid $brand-primary;
      margin-left: -30px;
      white-space: nowrap;

      &:before {
        content: "";
        position: absolute;
        left: 30px;
        top: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $brand-primary transparent;
        z-index: 9999;
      }
    }

    .mcdropdown-content {
      a {
        color: black;
        padding: 6px 16px;
        text-decoration: none;
        display: block;
        font-size: 13px;
        border-top: 1px solid #ccc;
        font-weight: 500;

        [class^="icon-"], [class*=" icon-"] {
          margin-right: 7px;
          font-size: 14px;
        }
      }
    }

    .mcdropdown-content {
      a:hover {
        background-color: #ddd;
      }
    }

    &:hover {
      .mcdropdown-content {
        display: block;
      }
    }
  }

  #menu {
    ul {
      ul {
        li {
          width: 100%;
          padding: 0 15px;
          height: 42px;
          line-height: 2;

          ul {
            top: -1px;
            left: 300px;
            min-height: 100%;
            border-left: 1px solid #e5e5e5;

            li {
              ul {
                left: 369px;
              }

              a {
                color: #7f7f7f;
                font-size: 11px;
                max-width: 330px;
              }
            }
          }

          a {
            font-size: $font-size-menu-link;
            color: $font-color-menu;
            display: block;
            padding: 10px 7px;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            clear: none;
            line-height: 2;
          }

          &:hover {
            color: $brand-primary;
            background-color: #e1e1e1;
            border-left: 1px solid $brand-primary;

            > a {
              color: $brand-primary;
            }
          }
        }

        li.parent {
          ul {
            li.parent:hover {
              > a:after {
                width: 6px;
                margin-top: 1px;
              }
            }
          }

          &:hover {
            > a:after {
              content: "\e907";
              font-family: 'icomoon' !important;
              display: flex;
              float: right;
              position: absolute;
              right: 4px;
              top: 11px;
            }
          }
        }
      }
    }
  }

  .menu-category-title {
    padding: 8px 25px;
    margin-top: 0;
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (max-width: 800px) {

  /* Open/Close */

  .stick_menu {
    display: block;

    span {
      color: #333;
      font-size: 20px;
      text-align: center;
      letter-spacing: 5px;
      position: absolute;
      z-index: 99;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      line-height: 50px;
      margin: 0 auto;
      display: block;
      cursor: pointer;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
    }
  }

  .open {
    > .stick_menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: rgba(225, 225, 225, 0.85);

      span {
        opacity: 0.1 !important;
      }
    }

    > .open_menu {
      left: 0;
    }
  }

  .open_menu {
    position: fixed;
    z-index: 99;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #FFFFFF !important;
    transition: 0.3s;
    box-shadow: -4px 0 15px 0 #373435;
    -webkit-box-shadow: -4px 0 15px 0 #373435;
    -moz-box-shadow: -4px 0 15px 0 #373435;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
  }

  /* Menu */
  #mcnav {
    .title {
      color: #666;
      font-size: 20px;
      text-transform: uppercase;
      text-shadow: 0.8px 0.8px 3px #111;
      height: 60px;
      line-height: 62px;
      display: block;
      padding: 0 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.045);
      box-shadow: -4px 0 5px 0 #373435;
      -webkit-box-shadow: -4px 0 5px 0 #373435;
      -moz-box-shadow: -4px 0 5px 0 #373435;
    }

    ul {
      li {
        float: none;
        display: block;

        > ul {
          position: relative;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          margin: 0;
          padding: 0;
          border: none;
          background: rgba(0, 0, 0, 0.1);
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          -ms-box-sizing: border-box;
        }
      }
    }

    .nav {
      ul {
        display: none;
        left: -9999px;
      }

      li.hover {
        > ul {
          position: static !important;
          display: block !important;
        }
      }
    }

    .parent {
      li {
        [class^="icon-"], [class*=" icon-"] {
          padding: 0 8px;

          &:before {
            padding-left: 10px;
          }
        }
      }
    }

    .parent {
      color: #000000;
      background-color: #FFFFFF !important;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.1;
      display: block;
    }

    ul {
      li {
        border-top: 1px solid rgba(255, 255, 255, 0.045) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.045) !important;

        a {
          color: #000000;
          background-color: #FFFFFF !important;
          font-size: 15px;
          font-weight: 500;
          line-height: 1.1;
          display: block;
          padding: 20px;
        }

        ul.parent {
          color: #000000;
          background-color: #FFFFFF !important;
          font-size: 16px !important;
          font-weight: 500 !important;
          line-height: 1.1 !important;
          display: block;
        }

        ul {
          li {
            color: #000000;
            background-color: #FFFFFF !important;
            font-size: 16px !important;
            font-weight: 500 !important;
            line-height: 1.1 !important;
            display: block;

            &.hover > .parent, &.hover {
              color: $brand-primary;
              background-color: #c9c9c9 !important;
              line-height: 1.1;
            }
          }
        }

        &.hover > .parent, > a.hover {
          color: $brand-primary;
          background-color: #e1e1e1 !important;
          line-height: 1.1;
        }
      }

      > a.hover {
        color: $brand-primary;
        background-color: #e1e1e1 !important;
        line-height: 1.1;
      }

      li.parent {
        > a:after {
          @include icon-arrow-right_2;
          font-family: 'icomoon' !important;
          display: flex;
          float: right;
        }
      }

      li.hover {
        > a:after {
          @include icon-arrow-down_2;
          font-family: 'icomoon' !important;
          display: flex;
          float: right;
        }
      }
    }

    #idButtonLogout {
      font-size: 24px;

      > a:before {
        @include icon-turn-off;
        font-family: 'icomoon' !important;
        display: flex;
        float: left;
        margin-right: 10px;
      }
    }

    #idAgentReferer {
      > a:before {
        content: "\e959";
        font-family: 'icomoon' !important;
        display: flex;
        float: left;
        margin-right: 10px;
      }
    }

    ul {
      li {
        ul {
          li {
            span {
              font-size: 24px;
              float: left;
              margin-top: 5.5%;
            }

            a {
              margin-left: 25px;
              padding: 20px 20px 20px 5px;
            }

            &.hover {
              > span {
                font-weight: 900;
              }
            }
          }

          &.hover {
            > .parent, &.hover {
              color: $brand-primary;
              background-color: #c9c9c9 !important;
              line-height: 1.1;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screenXs) {
  li.statistic-xs {
    a {
      > span.icon-statistiche {
        margin-right: 5px;
      }
    }
  }

  .open {
    > .open_menu {
      width: 300px;
    }
  }

  #menu {
    > ul > li > .parent,
    > ul > li:hover > .parent,
    > ul > li.hover > .parent,
    ul li li > .parent,
    ul li li:hover > .parent,
    ul li li.hover > .parent {
      background-repeat: no-repeat;
      background-position: 210px center;
    }
  }

  #mcnav {
    ul {
      li.active {
        a {
          color: $brand-primary !important;
        }
      }
    }
  }
}

@media screen and (max-width: 319px) {
  .open {
    > .open_menu {
      width: 200px;
    }
  }

  #menu {
    > ul > li > .parent,
    > ul > li:hover > .parent,
    > ul > li.hover > .parent,
    ul li li > .parent,
    ul li li:hover > .parent,
    ul li li.hover > .parent {
      background-repeat: no-repeat;
      background-position: 150px center;
    }
  }
}

@media (min-width: $screenMd) {
  #mcnav {
    ul {
      li.mcFirstItemslink:first-child {
        > a {
          padding-left: 0;
        }
      }
    }
  }
}

@media (min-width: $screenXXl) {
  .mcdropdown-content {
    a {
      font-size: 16px !important;
    }
  }
}
