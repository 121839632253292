/* Here are all the CSS elements related to the site SearchBox. */

/* SearchBox at the top of the page */
.site-search {
  .input-group {
    input#js-site-search-input {
      border-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-color: $brand-primary;
      background-color: $well-quaternary-bg;
      font-size: 14px;
      font-weight: 400;
      height: 40px;
      color: #000;
    }

    .input-group-btn {
      width: auto;

      button.btn-link {
        height: 40px;
        background-color: $brand-primary;
        color: #fff;
        border-radius: 5px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        padding: 10px;
        font-size: 14px;
        text-decoration: none;

        .glyphicon-search {
          top: -1px;
        }
      }
    }
  }

  /* Slider buttons (e.g., search facets) */
  .mc-slider {
    margin-top: 10px;
  }

  /* Autocomplete */
  .ui-autocomplete {
    li.ui-menu-item {
      a {
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        justify-content: space-between;

        .thumb {
          margin-right: 20px;

          img {
            max-width: 30px;
            max-height: 30px;
          }
        }

        .name {
          line-height: 1.8;
          margin-right: auto;
        }
      }

      .ui-state-active,
      .ui-widget-content .ui-state-active,
      .ui-widget-header .ui-state-active,
      .ui-state-hover,
      .ui-widget-content .ui-state-hover,
      .ui-widget-header .ui-state-hover,
      .ui-state-focus,
      .ui-widget-content .ui-state-focus,
      .ui-widget-header .ui-state-focus {
        background: #d1d1d1 !important;
        border: 1px solid #e1e1e1;

        &:hover {
          background-color: #e1e1e1;
          color: $brand-primary;
        }
      }
    }
  }
}

/* "View/Hide Prices" slider button at the top of the page (or in side menu on mobile) */
#mcViewPriceForm {
  .mc-slider {
    margin-top: 3px;
  }
}

/* "View/Hide Prices" slider button label */
.mc-visibility-label {
  font-size: 10px;
  line-height: 2.8;
  margin-left: 5px;
  white-space: nowrap;
}

/* Common ".mc-slider" settings for both the SearchBox and the "View/Hide Prices" slider button */
.site-search, #mcViewPriceForm {
  .mc-slider {
    max-width: 50px;

    .control-label {
      width: 45px;
      margin-right: 0;

      input {
        margin-right: 0;
        margin-bottom: 0;
      }

      input:checked + .in-slider:before {
        -webkit-transform: translateX(19px);
        -ms-transform: translateX(19px);
        transform: translateX(19px);
      }

      .in-slider.round {
        max-height: 21px;

        &:before {
          height: 16px;
          width: 15px;
          bottom: 2.2px;
        }
      }
    }
  }
}

/* Solr search results highlighting */
.search-results-highlight {
  font-weight: bold;
}

/* Search Facets Labels */
.mc-facet-search-label {
  vertical-align: middle;
  line-height: 41px;
  text-transform: none;
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
}

/* Media Queries */

@media (min-width: $screenXXl) {
  .site-search {
    margin-top: 5px;

    .input-group {
      input#js-site-search-input {
        font-size: 16px !important;
      }

      .input-group-btn.search-cont {
        button.btn-link {
          padding: 15px;

          .icon-search {
            font-size: 18px;
            line-height: 0.7;
          }
        }
      }
    }
  }

  .mc-facet-search-label {
    font-size: 14px !important;
  }
}

@media (min-width: $screenLg) and (max-width: $screenLgMax) {
  .site-search {
    .mc-slider {
      .control-label {
        input:checked + .in-slider:before {
          -webkit-transform: translateX(16px);
          -ms-transform: translateX(16px);
          transform: translateX(16px);
        }
      }
    }
  }
}

@media (min-width: $screenSmBig) and (max-width: $screenMd) {
  .mc-site-search-cont {
    min-height: 60px;
  }
  .site-search {
    padding: 0 25px;
  }
}

@media (max-width: $screenXs) {
  .mc-site-search-cont {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .site-search {
    margin: 8px 0;

    .input-group {
      position: relative;
      width: 100%;

      input#js-site-search-input {
        border-radius: 5px !important;
        height: 50px !important;
        padding-left: 50px;
      }

      .input-group-btn {
        position: absolute;

        button {
          z-index: 2;
          border-radius: 5px !important;
          font-size: 14px;
        }

        &.search-cont {
          left: 0;
          z-index: 99;

          button.btn-link {
            color: $brand-primary;
            height: 35px;
            padding: 12px 10px;
            border-radius: 5px !important;
            font-size: 16px;
            background-color: transparent;

            .icon-search {
              font-size: 25px;
            }
          }
        }

        &.barcode-cont {
          right: 4px;
          width: auto;

          button.btn-barcode {
            color: #ffffff;
            height: auto;
            padding: 6px 9px;
            margin: 5px 0;
            background-color: $mc-orange;

            .icon-barcode {
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  .mc-mobile-filters {
    border-bottom: 1px solid #eee;
    padding: 6px !important;
    background-color: #4a70c1;

    a {
      font-weight: 600;

      &, &:hover, &:focus {
        color: #fff;
      }

      &:before {
        @include icon-arrow-up;
        font-family: 'icomoon' !important;
        float: right;
      }
    }

    a.collapsed:before {
      @include icon-arrow-down_2;
      font-family: 'icomoon' !important;
    }

    #mcMobileFilters {
      margin-top: 5px;

      .mc-slider {
        margin-top: 0 !important;
      }

      label.mc-facet-search-label {
        color: #fff;
      }
    }

    #mcMobileFilters.in {
      border-top: 1px solid rgb(255, 255, 255);
      padding-top: 10px;
    }
  }
}
