/* Here are all the custom CSS elements related to the "Typeahead" library. */

.twitter-typeahead {
  position: absolute !important;    // !important to overwrite default "position: relative"
}

.typeahead {
  background-color: #fff;

  &:focus {
    border: 2px solid #0097cf;
  }
}

.typeahead, .tt-query, .tt-hint {
  width: auto;
  height: auto;
  padding: 8px 12px;
  border: 2px solid #ccc;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  outline: none;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu, .gist {
  text-align: left;
}

.gist {
  font-size: 14px;
}

.tt-menu {
  width: 422px;
  margin: 6px 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 14px;
  line-height: 20px;

  p {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
    color: #fff;
    background-color: $brand-primary;
  }

  &.tt-cursor {
   color: #fff;
   background-color: $brand-primary;
 }
}

#custom-templates {
  .empty-message {
    padding: 5px 10px;
    text-align: center;
  }
}

#multiple-datasets {
  .league-name {
    margin: 0 20px 5px 20px;
    padding: 3px 0;
    border-bottom: 1px solid #ccc;
  }
}

#rtl-support {
  .tt-menu {
    text-align: right;
  }
}
