/* Navigation Links on the top left above the site logo */
.nav__links--account {
  padding: 5px;
  font-size: 12px;

  li {
    color: #a1a0a0;
    margin-right: 20px;

    .glyphicon {
      color: #8ac0e1;
    }

    .mc-name {
      color: #646262;
      font-weight: 700;

      a {
        color: #646262;
      }
    }
  }
}

/* Account Navigation Component at the top of the page */
.accNavComponent {
  display: none;
}

/* Account Navigation sections on the top */
#accNavComponentDesktopOne, #accNavComponentDesktopTwo {
  padding: 0;
  background-color: $meta-nav-background;

  ul.nav__links {
    width: 100%;
    padding: 0;
    margin-bottom: 0;

    li {
      text-transform: capitalize;
      list-style: none;
      font-size: 12px;
      color: $meta-nav-color;
      font-weight: 400;
      padding: 0;
      border-right: 1px solid $meta-nav-border-color;
      border-bottom: 1px solid $meta-nav-border-color;

      &:hover {
        background-color: $meta-nav-hover-background;
      }

      a {
        color: $meta-nav-color-open;
        display: block;
        padding: 25px 0 25px 30px;
        font-size: 15px;
        font-weight: 400;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

/* Navigation links list */
ul.nav__links {

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  li {
    float: left;
    list-style-type: none;
  }

  &::after {
    clear: both;
  }

  @media (max-width: $screenSmMax) {
    &:before {
      content: " ";
      display: table;
    }
  }
}
