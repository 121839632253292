/* Category Navigation bar */
.mc-category-navigation {
  position: relative;

  .mc-quickorder-button {
    position: absolute;
    right: 0;
    bottom: 0;

    .content {
      button {
        border-radius: 5px;
        border-top-left-radius: 22px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        clip-path: polygon(100% 0%, 10% 0%, 0% 100%, 100% 100%);
        padding: 13px 40px;
        background-image: linear-gradient(#04a0eb, #0c7fbb);
        border: 0;
        color: #fff;

        .mc-quickorder-btn-lbl {
          margin: 0 5px;
          text-transform: uppercase;
        }
      }
    }
  }
}

/* Right-aligned Navigation bar */
.mc-nav__right {
  .nav-mc-top-links {
    margin-right: 20px;
    padding-top: 8px;
    width: 100%;

    .nav-mc-top-links__child-wrap {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      justify-content: flex-end;
      padding-top: 10px;
      font-weight: 700;

      a {
        color: $text-color;
        float: left;
        margin-right: 12px;
        font-size: 12px;
      }
    }
  }
}

/* Categories Levels Navigation */
div#mcnav {
  ul.navbar-nav {
    ul.dropdown-menu {
      top: -3px !important;
      left: 0 !important;
      width: 280px !important;
      min-width: 10em !important;
      max-width: 30em !important;
      box-shadow: none;

      &.first-level {
        margin-top: 50px;
      }

      &.second-level, &.third-level {
        margin-left: 277px !important;
        margin-top: 2px !important;
      }
    }
  }
}
