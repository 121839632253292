/* Breadcrumbs section */
.breadcrumb-section {
  @extend .full-width-padding;

  .breadcrumb {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
  }
}

.breadcrumb-section li:before,
.breadcrumb-section .breadcrumb li:before {
  padding: 0;
  color: #303030;
}

/* Breadcrumbs section customization */
.breadcrumb-section, .breadcrumb-section .breadcrumb {
  background: $mc-breadcrumb-background;

  li, li.active, li > a {
    color: #fff;
  }

  li:before {
    padding: 0;
  }

  .mc-share {
    padding: 10px 0;
    margin-left: auto;

    span {
      color: #fff;
      font-size: 12px;
    }

    display: none;
  }
}

/* Media Queries */

@media (max-width: $screenXs) {
  .breadcrumb-section {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: $screenMd) {
  .breadcrumb-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}
