/* Here are all the CSS elements related to the "Socials" section inside the breadcrumbs. It heavily relies on the
 JS-Socials library. */

/* Hidden Menu in Breadcrumbs with socials */
.menu-hidden {
  margin-right: -18em;
}

.jssocials-shares {
  margin: 0.2em 0;

  * {
    box-sizing: border-box;
  }
}

.jssocials-share {
  display: inline-block;
  vertical-align: top;
  width: 45px;
  margin: 0.3em 0.6em 0.3em 0;

  &:last-child {
    margin-right: 0;
  }
}

.jssocials-share-logo {
  width: 1em;
  vertical-align: middle;
  font-size: 1.5em;
}

img.jssocials-share-logo {
  width: auto;
  height: 1em;
}

.jssocials-share-link {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  border-radius: 50%;
  padding: .5em .6em;
  color: #fff;
  -webkit-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
  transition: background 200ms ease-in-out, border-color 200ms ease-in-out;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }

  &.jssocials-share-link-count {
    padding-top: .2em;

    .jssocials-share-count {
      display: block;
      font-size: .6em;
      margin: 0 -.5em -.8em -.5em;
    }
  }

  &.jssocials-share-no-count {
    padding-top: .5em;

    .jssocials-share-count {
      height: 1em;
    }
  }
}

.jssocials-share-label {
  padding-left: 0.3em;
  vertical-align: middle;
}

.jssocials-share-count {
  line-height: 1.5em;
  vertical-align: middle;
}

/* Twitter */
.jssocials-share-twitter {
  .jssocials-share-link {
    background: #00aced;

    &:hover {
      background: #0087ba;
    }
  }
}

/* Facebook */
.jssocials-share-facebook {
  .jssocials-share-link {
    background: #3b5998;

    &:hover {
      background: #2d4373;
    }
  }
}

/* Google Plus */
.jssocials-share-googleplus {
  .jssocials-share-link {
    background: #dd4b39;

    &:hover {
      background: #c23321;
    }
  }
}

/* LinkedIn */
.jssocials-share-linkedin {
  .jssocials-share-link {
    background: #007bb6;

    &:hover {
      background: #005983;
    }
  }
}

/* Pinterest */
.jssocials-share-pinterest {
  .jssocials-share-link {
    background: #cb2027;

    &:hover {
      background: #9f191f;
    }
  }
}

/* E-Mail */
.jssocials-share-email {
  .jssocials-share-link {
    background: #3490F3;

    &:hover {
      background: #0e76e6;
    }
  }
}

/* StumbleUpon */
.jssocials-share-stumbleupon {
  .jssocials-share-link {
    background: #eb4823;

    &:hover {
      background: #c93412;
    }
  }
}

/* WhatsApp */
.jssocials-share-whatsapp {
  .jssocials-share-link {
    background: #29a628;

    &:hover {
      background: #1f7d1e;
    }
  }
}

/* Telegram */
.jssocials-share-telegram {
  .jssocials-share-link {
    background: #2ca5e0;

    &:hover {
      background: #1c88bd;
    }
  }
}

/* Line */
.jssocials-share-line {
  .jssocials-share-link {
    background: #25af00;

    &:hover {
      background: #1a7c00;
    }
  }
}

/* Viber */
.jssocials-share-viber {
  .jssocials-share-link {
    background: #7b519d;

    &:hover {
      background: #61407b;
    }
  }
}

/* Pocket */
.jssocials-share-pocket {
  .jssocials-share-link {
    background: #ef4056;

    &:hover {
      background: #e9132e;
    }
  }
}

/* Messenger */
.jssocials-share-messenger {
  .jssocials-share-link {
    background: #0084ff;

    &:hover {
      background: #006acc;
    }
  }
}

/* VKontakte */
.jssocials-share-vkontakte {
  .jssocials-share-link {
    background: #45668e;

    &:hover {
      background: #344d6c;
    }
  }
}

.jssocials-share-count-box {
  display: inline-block;
  position: relative;
  height: 2.5em;
  padding: 0 .3em;
  line-height: 1;
  margin-left: 0.3em;
  vertical-align: middle;
  background: #f5f5f5;
  cursor: default;
  -webkit-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
  transition: background 200ms ease-in-out, border-color 200ms ease-in-out;

  &:hover {
    background: gainsboro;
    border-color: transparent gainsboro transparent transparent;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0.85em;
    left: -0.3em;
    width: 0;
    height: 0;
    border-width: 0.4em 0.4em 0.4em 0;
    border-style: solid;
    border-color: transparent #f5f5f5 transparent transparent;
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
    transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
  }

  &.jssocials-share-no-count {
    display: none;
  }

  .jssocials-share-count {
    line-height: 2.5em;
    color: #444;
  }
}

#social-menu {
  position: fixed;
  right: 0;
  text-align: center;
  list-style: none;
  color: white;
  padding-top: 2em;
  z-index: -10;

  ul {
    margin: none !important;
  }

  li {
    margin: unset !important;

    a {
      border-left-style: none;
      border-bottom: none;
      display: inline-block;
      padding-top: 1em;
      padding-bottom: 1em;
      width: 100%;
      color: white;
      transition: background-color 0.6s ease;

      &:hover {
        transition: background-color 0.6s ease;
      }
    }
  }
}

/* Media Queries */

@media (min-width: $screenXl) {
  #social-menu {
    width: 230px;
  }

  .jssocials-share-link {
    width: 45px !important;
    height: 45px !important;
  }
}

@media (max-width: $screenXl) {
  #social-menu {
    width: 210px;
  }

  .jssocials-share-link {
    width: 40px !important;
    height: 40px !important;
  }

  .jssocials-share {
    width: 40px;
  }
}
