/* Footer */
footer {
  margin-top: 30px;
  background: $footer-background;
  color: $footer-text-color;
  font-weight: 300;

  img.store-badge {
    max-width: 120px;
    width: 120px;
    display: none;
  }
}

/* Top Footer section */
.footer__top {
  padding-bottom: 50px;
  padding-top: 50px;

  .footer__nav--container {
    .footer__nav--links {
      padding-left: 0;
      width: 100%;
      margin: 0;
      list-style: none;

      .footer__link {
        margin-top: 10px;
      }

      a {
        color: $footer-text-color;
        font-size: 14px;
      }
    }
  }
}

/* Left Footer section */
.footer__left {
  @extend .full-width-padding;
}

/* Right Footer section */
.footer__right {
  @extend .full-width-padding;

  .footer__dropdown {
    select.form-control {
      padding: 2px 13px;
    }

    .form-group {
      margin-bottom: 0;
    }

    .form-control {
      cursor: pointer;
      background-color: $footer-background;
      color: $footer-text-color;
      padding: 7px 10px;
      height: 26px;
      line-height: 20px;
      font-size: 14px;

      option {
        background-color: $footer-background;
      }

      &:focus::-ms-value {
        background-color: $footer-background;
      }
    }
  }
}

/* Copyright Footer section */
.footer__copyright {
  background: $copyright-background;
  background-color: #eaeaea;
  padding: 25px 0;
  color: $copyright-color;
  text-align: center;
}

/* Custom MC Footer */
footer.mc-footer {
  padding: 40px 0;

  .mc-footer-item {
    [class^="icon-"],
    [class*=" icon-"] {
      font-size: 40px;
      color: $brand-primary;
    }

    .mc-footer-content {
      margin-left: 8px;

      .title {
        h5 {
          margin: 0;
          color: #222222;
          font-weight: 600;
          margin-bottom: 5px;
          font-size: 15px;
        }
      }

      .text {
        font-size: 13px;
        color: #9a9a9a;
        max-width: 180px;

        a {
          color: #9a9a9a;
        }
      }
    }
  }

  .footer-right {
    > .content {
      > .mc-footer-item {
        justify-content: flex-end;
      }
    }
  }

  .footer-center {
    > .content {
      > .mc-footer-item {
        justify-content: center;
      }
    }
  }

  .mc-footer-contact-section {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 25px;
    margin: 60px 0 40px 0;
  }

  .newsletter-slot {
    .content {
      margin-top: -105px;
    }
  }
}

/* Footer box containers */
.mc-footer-boxed {
  padding: 0 120px;
  position: relative;
}

/* Footer site logo section */
.footer-logo-box {
  > .banner__component {
    a {
      > img {
        max-width: 180px;
      }
    }
  }
}

/* Footer Business Description section */
.footer-desc {
  margin-bottom: 30px;
  margin-top: 15px;

  p {
    margin: 0 0 5px;
    font-weight: 600;

    > a {
      color: #9a9a9a;
      font-weight: 700;

      &:hover {
        color: $brand-primary;
      }
    }
  }
}

/* Footer links section containers */
.footer__nav--container {
  ul {
    list-style: none;
  }
}

/* Footer links section */
.footer__link {
  a {
    font-size: 14px;
    line-height: 1.6;
    color: #9a9a9a;
    font-weight: 700;

    &:hover {
      color: $brand-primary;
    }
  }
}

/* Footer social icons section */
.footer-icons {
  margin-top: 15px;

  [class^="icon-"],
  [class*=" icon-"] {
    font-size: 30px;
    margin-right: 15px;
  }

  .icon-facebook-circular-logo {
    color: #1877f2;
  }

  .icon-linkedin {
    color: #0274b3;
  }

  .icon-youtube {
    color: #ff0000;
  }
}

/* Footer social icons */
.footer-icon-img {
  width: 30px;
  margin-right: 10px;
}

/* Footer bottom description section */
.mc-bottom-info {
  &.mc-footer-content {
    margin-left: 0;
  }
}

/* Footer contiguous elements container */
.footer-bottom-cont {
  .f-item {
    margin-right: 30px;

    a,
    span,
    p {
      color: #a6a6a6;
    }
  }
}

/* Media Queries */

/* Mobile */
@media screen and (max-width: $screenXs) {
  footer.mc-footer {
    padding: 20px 0;
    margin-top: 20px;

    .footer-right, .footer-center {
      > .content {
        > .mc-footer-item {
          justify-content: left;
          margin-top: 10px;
        }
      }
    }

    .footer-bottom-cont {
      display: block;
    }
  }

  .mc-footer-boxed {
    padding: 0 20px;
  }
}

@media (max-width: $screenSm) {
  .footer__top {
    .footer__nav--container {
      margin-bottom: 10px;
      text-align: center;
    }
  }
}

/* Tablet */
@media (min-width: $screenSmBig) and (max-width: $screenMd) {
  footer.mc-footer {
    margin-top: 60px;
  }
  .mc-footer-boxed {
    padding: 0;
  }
}

@media (max-width: $screenMd) {
  .footer__top {
    padding-bottom: 38px;
  }

  .footer__right {
    border-top: 1px solid $footer-border-color;
    padding-top: 30px;
    margin-top: 25px;
  }
}
