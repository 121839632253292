/*  Navigation Order Tools (not used) */
.nav-order-tools {
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:before {
    position: relative;
    top: 23%;
    display: inline;
    font-family: 'Glyphicons Halflings', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e032";
    color: $nav-icon-color;
    font-size: 30px;
  }
}

/* Navigation Order Tools Active */
.js-nav-order-tools--active {
  .nav-order-tools__child-wrap {
    display: block;
  }
}

/* Navigation Order Tools Child Wrap */
.nav-order-tools__child-wrap {
  font-size: $font-size-base;
  line-height: $line-height-base;
  text-align: left;
  position: absolute;
  border: 1px solid $nav-border-color;
  background-color: $nav-icon-dropdown-background;
  right: -1px;
  top: $navigation--middle-height;
  width: 260px;
  z-index: 10;

  div {
    border-bottom: 1px solid $nav-icon-hover-background;

    &:last-child {
      border-bottom: none;
    }
  }

  a {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    color: $text-color;
    padding: $grid-gutter-width $grid-gutter-width*1.5;

    &:hover {
      background-color: $nav-icon-hover-background;;
    }
  }
}

/* Media Queries */

@media (min-width: $screenXsMax) {
  .nav-order-tools {
    position: static;

    &:before {
      top: 3px;
    }
  }

  .nav-order-tools__child-wrap {
    width: 100vw;
    top: 103px;
  }
}

@media (min-width: $screenSm) {
  .nav-order-tools {
    border-left: 0;
    border-right: 1px solid $nav-border-color;
    padding: 0 15px;
  }
}

@media (min-width: $screenMd) {
  .nav-order-tools {
    padding: 0 30px;
  }
}

