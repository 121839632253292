/* Here are all the custom CSS elements related to the "Bootstrap Tagsinput" library. */

.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: inline-block;
  padding: 4px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
  width: 100%;
  min-height: 32px;

  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
    min-width: 200px;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .single {
    height: 20px;
  }

  .tag {
    display: inline-block;
    height: 21px;
    font-size: 10px;
    font-weight: 500;
    color: #fff;
    line-height: 20px;
    padding: 0 12px;
    -webkit-border-radius: 16px;
    border-radius: 16px;
    background-color: $brand-primary;
    margin-bottom: 0;
    margin-right: 5px;

    [data-role="remove"] {
      margin-left: 8px;
      cursor: pointer;

      &:after {
        content: "x";
        padding: 0 2px;
      }

      &:hover {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);

        &:active {
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        }
      }
    }
  }

  &.form-control {
    input {
      &::-moz-placeholder {
        color: #777;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #777;
      }

      &::-webkit-input-placeholder {
        color: #777;
      }
    }
  }
}
