/* Here are all the custom CSS elements related to the "Bootstrap Notify" library. */

[data-notify="container"] {
  &[class*="alert-pastel-"] {
    background-color: rgb(255, 255, 238);
    border-width: 0;
    border-left: 15px solid rgb(255, 240, 106);
    border-radius: 0;
    box-shadow: 0 0 5px rgba(51, 51, 51, 0.3);
    letter-spacing: 1px;
    z-index: 10031 !important;    // Ensure alert is displayed in front of other elements

    > [data-notify="title"] {
      color: rgb(80, 80, 57);
      display: block;
      font-weight: 700;
      margin-bottom: 5px;
    }

    > [data-notify="message"] {
      font-weight: 400;
    }
  }

  &.alert-pastel-info {
    border-left-color: rgb(255, 179, 40);
  }

  &.alert-pastel-danger {
    border-left-color: rgb(255, 103, 76);
  }

  &.alert-pastel-success {
    border-left-color: rgb(50, 255, 50);
  }
}
