/* Here are all the CSS elements related to the "Banner" / "Simple Banner" component (e.g., the one containing the
 site logo, or the one located inside the Homepage). */

.banner-component {
  width: 100%;

  a {
    @extend .clearfix;
    display: block;
    color: $text-color;
    text-decoration: none;
  }

  .title {
    font-weight: bold;
  }

  .thumb {
    padding: ($grid-gutter-width/2) 0;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.simple-banner-component, .banner__component, .simple-responsive-banner-component, .banner__component--responsive {
  a {
    display: block;
  }

  img {
    width: 100%;
    height: auto;
  }
}

/* From style-xs.less */

@media (max-width: $screenXs) {
  .simple-banner-component, .banner__component {
    img {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: $screenXsMax) {
  .simple-banner-component, .banner__component {
    @include make-row;
  }
}

@media (max-width: $screenSm) {
  .simple-banner-component, .banner__component {
    margin: 0;
  }

  .carousel__component--carousel {
    width: 100%;
    margin: 0;
  }
}
