/* Here are all the CSS elements related to the "ColorBox" / "Modal" component. */

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/

#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#cboxWrapper {
  max-width: none;
}

#cboxOverlay {
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious, #cboxNext, #cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox, #cboxContent, #cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/

#cboxOverlay {
  background: #000;
}

#colorbox {
  outline: 0;

  .forgotten-password {
    .btn-primary {
      font-size: 13px;
      margin-top: 35px;
    }
  }
}

#cboxTopLeft {
  width: 14px;
  height: 14px;
}

#cboxTopCenter {
  height: 14px;
}

#cboxTopRight {
  width: 14px;
  height: 14px;
}

#cboxBottomLeft {
  width: 14px;
}

#cboxBottomCenter {
  height: 43px;
}

#cboxBottomRight {
  width: 14px;
}

#cboxMiddleLeft {
  width: 14px;
}

#cboxMiddleRight {
  width: 14px;
}

#cboxContent {
  background: #fff;
  overflow: visible;

  .cart_popup_error_msg {
    margin-bottom: $grid-gutter-width;
  }
}

.cboxIframe {
  background: #fff;
}

#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}

#cboxCurrent {
  position: absolute;
  bottom: -25px;
  left: 58px;
  font-weight: bold;
  color: #7C7C7C;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  position: absolute;
  bottom: -29px;
  width: 23px;
  height: 23px;
  text-indent: -9999px;

  /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
  &:active {
    outline: 0;
  }
}

#cboxPrevious {
  left: 0;
  background-position: -51px -25px;

  &:hover {
    background-position: -51px 0;
  }
}

#cboxNext {
  left: 27px;
  background-position: -75px -25px;

  &:hover {
    background-position: -75px 0;
  }
}

.cboxSlideshow_on {
  #cboxSlideshow {
    background-position: -125px 0;
    right: 27px;

    &:hover {
      background-position: -150px 0;
    }
  }
}

.cboxSlideshow_off {
  #cboxSlideshow {
    background-position: -150px -25px;
    right: 27px;

    &:hover {
      background-position: -125px 0;
    }
  }
}

#cboxTitle {
  background: transparent;
  position: absolute;
  top: 0;
  padding: 43px 40px 15px;
  height: $cboxTitleHeight;
  display: table;
  width: 100%;
  white-space: nowrap;

  .headline {
    font-size: 20px;
    line-height: $cboxTitleLineHeight;
    font-weight: bold;
    width: 100%;
    vertical-align: middle;
  }
}

#cboxClose {
  position: absolute;
  right: 13px;
  top: 10px;
  background: transparent;
  border: 0 none;
  color: $modal-close-btn-color;
  padding: 5px;
  font-size: 25px;

  &:active {
    outline: 0;
  }
}

#cboxLoadedContent {
  margin-top: 70px;
  padding: 15px $grid-gutter-width*2 ($grid-gutter-width + 10) $grid-gutter-width*2;

  .add-to-cart-item {
    margin-bottom: $grid-gutter-width*2;
  }

  .addressEntry + .addressEntry,
  .saved-payment-entry + .saved-payment-entry {
    margin-top: 55px;
  }

  .addressEntry ul, .saved-payment-entry ul {
    margin-top: -$grid-gutter-width/2;
    margin-bottom: $grid-gutter-width;
  }

  .modal-details {
    line-height: 1.8em;
  }

  .modal-actions {
    margin-top: $grid-gutter-width;

    .btn {
      margin-top: $grid-gutter-width;
    }
  }
}

/* Modal */

.modal__top {
  padding-bottom: ($grid-gutter-width + 12);
  margin-bottom: ($grid-gutter-width + 10);
  border-bottom: 1px solid $border-color;

  .modal__top--label {
    color: $text-color;
    font-weight: bold;
    font-style: italic;
  }

  .modal__top--text {
    font-style: italic;
  }
}

.modal__text--bold {
  font-weight: bold;
}

.modal__bottom {
  margin-bottom: ($grid-gutter-width * 2);
  font-size: 15px;
}

.quote-discount__modal--label {
  font-size: 15px;
  font-weight: 600;
  color: $text-color;
  text-transform: capitalize;
  vertical-align: middle;
  display: table-cell;
  height: 40px;
}

.quote-discount__modal--input {
  margin-bottom: ($grid-gutter-width - 3);
  width: 100%;

  input[type=number] {
    -moz-appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.quote-discount__modal--input__label {
  display: table-cell;
  vertical-align: middle;
  font-size: 18px;
  padding-right: ($grid-gutter-width / 2);
  width: 25px;
  text-align: right;
}

.quote-discount__modal--original__total {
  font-size: 15px;
  font-style: italic;
  border-bottom: 1px solid $border-color;
  color: $text-color;
  padding-bottom: ($grid-gutter-width - 7);
  margin-bottom: ($grid-gutter-width - 11);
  margin-top: ($grid-gutter-width / 2);
}

.quote-discount__modal--new__total {
  color: $text-color;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: ($grid-gutter-width + 27);
}

.confirm-popup-content {
  display: none;
}


/* Media Queries */

@media (max-width: $screenXs) {
  #cboxTitle {
    z-index: 10;
    height: auto !important;
    .headline {
      font-size: 16px !important;
    }
  }

  #cboxLoadedContent {
    z-index: 11;
  }

  #cboxClose {
    z-index: 12;
  }
}

@media (max-width: $screenXsMax) {
  #cboxTitle {
    @include clearfix;
    height: $cboxTitleHeight + $cboxTitleLineHeight;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  #cboxLoadedContent {
    padding: 15px $grid-gutter-width ($grid-gutter-width + 10) $grid-gutter-width;

    .addressEntry {
      ul {
        margin-top: -($grid-gutter-width + 5);
      }
    }
  }

  #cboxContent {
    .sku-quantity {
      font-size: $font-size-large-1;
    }
  }
}

@media (min-width: $screenMd) {
  #colorbox #cboxTitle {
    .headline-text {
      display: block !important;
    }
  }
}
