/* Top Navigation section full width padding */
.navigation.navigation--top {
  @extend .full-width-padding;
}

/* Top Navigation section */
.navigation--top {
  .nav__left {
    padding: 9px 0 7px;

    .simple-banner-component img,
    .banner__component {
      img {
        width: auto;
      }
    }
  }

  .nav__right {
    .nav__links--account {
      margin: 0;
      padding-left: 0;
      float: right;

      // This is a alternative approach to deal with smartedit
      div[data-smartedit-component-id="HeaderLinksSlot"] {
        display: inline-block;
        float: left;

        li:last-child {
          a {
            padding-right: 12px;
          }
        }
      }

      li {
        display: inline-block;
        font-size: 12px;
        color: $meta-nav-color;
        font-weight: 400;
        text-transform: uppercase;

        &:last-child {
          a {
            padding-right: 0;
          }
        }

        &.logged_in {
          padding: 10px;
        }

        button {
          background: transparent none repeat scroll 0% 0%;
          border: medium none;
          color: rgb(0, 104, 179);
          font-size: 1em;
          text-transform: uppercase;

          &:hover {
            background: none;
            text-decoration: none;
            color: $meta-nav-hover-color;
          }
        }

        a {
          color: inherit;
          cursor: pointer;
          text-transform: uppercase;
          font-weight: 400;
          padding: 12px;
          display: block;
          font-size: 1em;
          line-height: 1.329em;

          &:hover {
            text-decoration: none;
            color: $meta-nav-hover-color;
          }
        }
      }

      .myAccountLinksHeader, .myCompanyLinksHeader {
        background-color: transparent;
        color: inherit;
        display: block;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        padding: 0 12px;

        &::after {
          clear: both;
        }

        &:hover, &:focus {
          color: $meta-nav-hover-color;
          text-decoration: none;
        }
      }

      .myAccountLinksHeader.show,
      .myCompanyLinksHeader.show {
        background-color: $meta-nav-background;
        color: $meta-nav-color-open;
        text-decoration: none;
        display: block;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        padding: 0 12px;
      }
    }
  }
}

/* Middle Navigation section */
.navigation--middle {
  .btn {
    background-color: transparent;
    width: 100%;
    padding: 0;
    height: 100%;
    color: $btn-primary-bg;
    border: none;
  }

  .nav__left {
    .site-search {
      background-color: transparent;
      z-index: 2;

      .ui-front {
        padding: ($grid-gutter-width/2) 0;
      }

      .glyphicon-search {
        color: $searchbar-icon-color;
        font-size: 17px;
      }

      .input-group {
        background-color: $input-background;
        min-height: 38px;
        border: 1px solid $input-border-color;

        .form-control {
          border: 0;
          box-shadow: none;
          height: 38px;
          background-color: transparent;
        }

        .btn {
          width: 40px;
          padding: 0;

          &:hover {
            background-color: transparent;
          }
        }
      }

      ul.ui-autocomplete {
        display: none;
        position: absolute;
        background: $autocomplete-background;
        left: 0;
        right: 0;
        -webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
        z-index: 100;
        width: auto !important;
        padding-left: 0;
        padding-top: 0;
        list-style: none;
        margin: 0;

        li {
          border-top: 1px solid $border-color-2;
          display: block;
          width: 100%;
          padding: 10px;

          a {
            display: table;
            width: 100%;
            color: inherit;
            text-decoration: none;
          }

          .thumb {
            display: table-cell;
            vertical-align: middle;
            padding: 0 10px 0 0;
          }

          .name {
            display: table-cell;
            padding: 10px;
            vertical-align: middle;
            width: $full-width;
          }

          .thumb + .name {
            padding: 0 10px;
          }

          .price {
            display: table-cell;
            vertical-align: middle;
            padding: 0 10px;
          }
        }

        li.ui-state-focus {
          border-left: none;
          border-right: none;
          border-bottom: none;
          margin: 0;
          background: $table-2n-line-bg;
          color: $text-color;
        }
      }

      ul.ui-autocomplete.active {
        display: block;
      }
    }
  }

  .nav__right {
    padding-right: 25px;

    ul.nav__links--shop_info {
      margin-bottom: 0;

      .nav-location {
        min-width: 60px;
        border: 0;
        border-left: 1px solid $nav-border-color;
        border-right: 1px solid $nav-border-color;

        span {
          font-size: 30px;
          top: 27%;
          color: $nav-icon-color;
        }
      }

      .nav-order-tools {
        border: 0;
      }
    }
  }

  .nav-order-tools,
  .nav-location,
  .nav-cart {
    height: $navigation--middle-height;
  }

  .nav-order-tools,
  .nav-location,
  .nav-cart,
  .btn {
    &:hover,
    &:active,
    &:focus {
      background-color: $nav-icon-hover-background;
      box-shadow: none;
    }
  }

  .nav-cart {
    float: right;
    padding: 0 10px;

    .mini-cart-link {
      color: $nav-icon-color;
    }

    .mini-cart-count {
      float: right;
      font-size: 12px;
      text-transform: uppercase;

      .nav-items-total {
        position: relative;
        right: 0;
        top: 5px;
      }
    }

    .mini-cart-icon {

      .glyphicon-shopping-cart {
        font-size: 22px;
      }
    }
  }

  .mobile-menu {
    height: $navigation--middle-height;

    button {
      font-size: 18px;
      line-height: 1.33;
      border-radius: 0;
      height: 100%;
      width: 100%;
      padding: 0;
    }

    span {
      position: relative;
      cursor: pointer;
      margin: 0 auto;
      color: $nav-icon-color;
      font-size: 29px;
      top: 2px;
    }
  }
}

/* Bottom Navigation section */
.navigation--bottom {
  background-color: $nav-cat-background;
  border: 0;
  border-bottom: 1px solid $nav-cat-border;

  &.js-enquire-offcanvas-navigation {
    .close-nav {
      button {
        span {
          color: $offcanvas-nav-header-color;
          font-size: 25px;
        }
      }
    }
  }

  &:before, &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  .column-20-percent {
    float: left;
    width: 20%;
  }

  .nav__links--products {
    padding: 0;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;

    .nav__link {
      a {
        padding: 13px;
      }
    }

    .nav__links--secondary {
      display: none;
      background: $nav-subcat-background;
      position: absolute;
      z-index: 1000;
      left: 0;
      border: 1px solid $nav-border-color;
      border-top: 0;
      padding: 0 10px;
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

      .sm-back {
        display: none;
      }

      .row {
        position: relative;
      }

      .sub-navigation-section {
        position: static;
        padding: 10px;
      }

      .sub-navigation-section.double {
        position: relative;
        min-height: 1px;
        padding: 10px 0 10px 0;
      }

      .sub-navigation-section {
        .sub-navigation-section-column {
          float: left;
          padding: 0 10px;
        }
      }

      .sub-navigation-section {
        .sub-navigation-list {
          padding-left: 0;
          list-style: none;

          li {
            border-bottom: 1px solid $nav-subcat-border-color;

            &:last-child {
              border-bottom: none;
            }

            a {
              display: block;
              color: $nav-subcat-color;
              line-height: 30px;
              padding-left: 5px;

              &:hover, &:focus {
                background-color: $nav-subcat-hover-background;
                text-decoration: none;
              }
            }
          }
        }
      }

      .sub-navigation-list.has-title {
        margin-top: 40px;
      }

      .title + .sub-navigation-list.has-title {
        margin-top: 0;
      }
    }
  }

  .show-sub {
    .nav__link {
      a {
        position: relative;

        &:after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-right: 10px solid rgba(0, 0, 0, 0);
          border-left: 10px solid rgba(0, 0, 0, 0);
          border-bottom: 10px solid $nav-subcat-background;
          display: block;
          bottom: 0;
          left: 50%;
          margin: 0 0 0 -10px;
          z-index: 1001;
        }
      }
    }

    .sub__navigation {
      display: block !important;
      top: $navigation--middle-height;
    }
  }

  .nav__link {
    display: inline-block;
    width: 100%;
  }


  .nav__links--primary-has__sub {

    .nav__link--drill__down {
      display: inline-block;
      width: 33%;
      color: $offcanvas-nav-cat-color;
      min-height: 30px;
      padding: 20px 0;
    }
  }

  .nav__links--primary {

    .sub-navigation-section {

      .title {
        font-size: 18px;
        line-height: 40px;
        text-transform: uppercase;
        color: $nav-subcat-title-color;
      }

      .sub-navigation-list {
        li {
          position: relative;
          display: block;
          border-bottom: 1px solid $offcanvas-nav-cat-border-color;
          float: none;
        }
      }
    }
  }

  .nav__links--mobile {
    &.offcanvasGroup1 {
      .offcanvasGroup2, .offcanvasGroup3 {

        li {
          border-bottom: 1px solid $offcanvas-meta-nav-border-color;
        }
      }
    }
  }
}

/* Media Queries */

@media (max-width: $screenXsMax) {
  .navigation--bottom {
    width: 100%;
    height: 83vh;
    left: -100%;
    margin-left: 0;

    .glyphicon-chevron-up, .glyphicon-chevron-down {
      left: 68vw;
    }
  }

  .navigation--middle {
    .row {
      &:first-child {
        border: none;
      }
    }

    .nav__right {
      ul.nav__links--shop_info {
        border-left: 1px solid $nav-border-color;
        display: table-cell;
        text-align: center;
        vertical-align: top;
        padding: 0;

        li {
          float: none;
        }
      }
    }

    .nav__left {
      .site-search {
        &.active {
          display: block !important;
        }

        display: none !important;
        left: 0;
        position: absolute;
        width: 100%;
        padding: 0 10px;
        border-top: 1px solid $nav-border-color;
      }
    }

    .componentContainer {
      text-align: center;
      vertical-align: top;
      border-left: 1px solid $nav-border-color;
    }

    .nav-cart {
      position: relative;
      float: none;
      padding: 0;

      .mini-cart-link {
        display: block;

        .mini-cart-icon {
          color: $nav-icon-color;
          left: -7px;
          top: 10px;

          .glyphicon-shopping-cart {
            width: $mobile-nav-icon-font-size;
            font-size: 22px;
            position: relative;
            right: -6px;
            top: 3px;
          }
        }

        .mini-cart-count {
          font-size: 12px;
          position: absolute;
          right: 44%;
          top: -13px;
          margin: 0;
        }

        .nav-items-total {
          display: inline-block;
          background-color: $nav-icon-color-secondary;
          border-radius: 50%;
          height: 25px;
          width: 25px;
          color: $btn-primary-color;
          line-height: 25px;
        }
      }
    }

    .mobile__nav__row {
      &.mobile__nav__row--table, .mobile__nav__row--table {
        display: table !important;
        width: 100%;
      }

      .mobile__nav__row--table-group {
        display: table-row-group !important;
      }

      .mobile__nav__row--table-row {
        display: table-row !important;

        &:before {
          display: table;
          content: " ";
        }
      }

      .mobile__nav__row--table-cell {
        display: table-cell !important;
        vertical-align: top;
      }

      .nav__links--shop_info {
        .componentContainer {
          margin-bottom: 0;
          overflow: hidden;
        }
      }

      span.glyphicon-align-justify {
        position: relative;
        cursor: pointer;
        margin: 0 auto;
        color: $nav-icon-color;
        font-size: 29px;
        top: 2px;
      }

      .componentContainer .nav-order-tools, .componentContainer {
        .nav-cart {
          font-size: 30px;
          line-height: $navigation--middle-height;
          padding: 0;
          width: 100%;
        }
      }

      .componentContainer {
        .mobile__nav__row--table-cell {
          border-right: 1px solid $nav-border-color;

          &:last-child {
            border-right: none;
          }
        }
      }

      .mobile__nav__row--seperator {
        border-left: 1px solid $nav-border-color;
      }
    }

    .mobile__nav__row--btn {
      padding: 0;
      vertical-align: top;
      height: $navigation--middle-height;
      font-size: 30px;
      line-height: $navigation--middle-height;

      .nav-order-tools, .mini-cart-link, .mobile__nav__row--btn-search {
        font-size: $mobile-nav-icon-font-size;
        line-height: $mobile-nav-icon-font-size*2;
        padding: 0;
        width: 100%;

        &:hover, &:focus, &:active {
          background-color: $nav-icon-hover-background;
          color: $btn-primary-bg;
          text-decoration: none;
        }
      }

      span.glyphicon {
        color: $nav-icon-color;
      }
    }
  }
}

@media (max-width: $screenSmMax) {
  .navigation--bottom {
    border-bottom: none;

    li {
      float: none;
    }

    .nav__links--primary {

      &.active {
        .sub__navigation {
          display: block;
        }
      }

      .row {
        margin: 0;
      }

      .sub__navigation {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 100%;
        padding: 0;
      }

      .sub-navigation-section {
        padding: 0;
        background: $offcanvas-nav-cat-background;

        .title {
          padding: 10px 20px 10px 50px;
          text-transform: uppercase;
          border-bottom: 1px solid $offcanvas-nav-cat-border-color;
        }

        .sub-navigation-list {
          margin-bottom: 0;
          padding-left: 0;
          list-style: none;
        }
      }

      .sm-back {
        color: $offcanvas-nav-cat-color;
        padding: 5px 50px;
        border-bottom: 1px solid $offcanvas-nav-cat-border-color;
        font-size: 18px;
        line-height: 50px;

        &::after {
          content: ' ';
        }

        &::before {
          position: relative;
          top: 1px;
          display: inline-block;
          font-family: 'Glyphicons Halflings', sans-serif;
          font-style: normal;
          font-weight: 400;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: .8em;
          margin-right: 1em;
        }
      }
    }

    .sm-back {
      &::before {
        content: "\e079";
        float: none;
      }
    }

    .navigation__overflow {
      left: -380px;
      top: 0;
      height: 83vh;
      overflow-y: auto;
      border-top: 1px solid $nav-border-color;
    }

    &.js-enquire-offcanvas-navigation {
      .close-nav {
        display: inline-block;
        cursor: pointer;
        height: $navigation--middle-height;
        width: 20%;
        vertical-align: top;
        border-left: 1px solid $nav-border-color;
        border-right: 1px solid $nav-border-color;

        button {
          background-color: $offcanvas-nav-header-background;
          height: 100%;
          width: 100%;
          display: inline-block;
          color: transparent;
          overflow: hidden;
          border: none;
        }
      }
    }

    .nav__links--mobile {
      overflow: hidden;

      li {
        overflow: hidden;
        background-color: $offcanvas-meta-nav-background;
        border: none;

        a {
          color: $offcanvas-meta-nav-color;
        }
      }

      .subNavList {
        padding-left: 0;

        li {
          padding: 5px 0;
          background: $meta-nav-background;
        }
      }

      div.sub-nav {
        .myAccountLinksHeader, .myCompanyLinksHeader {
          display: block;
          cursor: pointer;
          max-height: $navigation--middle-height;
          text-transform: uppercase;
        }

        a {
          border-bottom: 1px solid $offcanvas-meta-nav-border-color;

          span {
            float: right;
            line-height: 50px;
            left: 0;
            position: relative;
            height: 30px;
            top: -14px;
          }
        }
      }
    }

    .userGroup {
      position: relative;
      line-height: 50px;
      background-color: $offcanvas-nav-header-background;
      padding: 5px 12px 5px 20px;
      cursor: pointer;
      color: $offcanvas-nav-header-color;
      display: inline-block;
      width: 80%;
      float: left;
      height: $navigation--middle-height;
      text-transform: uppercase;
      text-align: left;

      a {
        border: none;
        color: $offcanvas-nav-header-color;

        &:hover {
          background-color: transparent !important;
        }
      }

      .myAcctUserIcon {
        font-size: 20px;
        position: relative;
        top: 4px;
      }

      .userName {
        display: inline-block;
        padding-left: 10px;
      }

      #signedInUserOptionsToggle {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        padding: 0;
      }
    }

    .glyphicon-chevron-up, .glyphicon-chevron-down {
      position: absolute;
      left: 260px;
      line-height: $navigation--middle-height;
      top: 0;
    }

    .nav__links--products {
      &.active {
        position: relative;
        left: -100%;
      }

      top: 0;
      bottom: 0;
      right: 0;
      background-color: $offcanvas-nav-cat-background;
      padding: 0;
      position: relative;
      left: 0;

      li {

      }
    }

    .nav__links--primary-has__sub {
      position: static;

      .nav__link {
        width: 67%;
      }
    }
  }
}

@media (min-width: $screenMd) {
  .navigation--bottom {
    .nav__links--primary {
      .sub-navigation-section {
        .sub-navigation-list {
          padding-left: 0;
          list-style: none;
          margin-top: 0;

          a {
            display: block;
            color: $nav-subcat-color;
            line-height: 30px;
            padding: 0 0 0 5px;

            &:hover {
              background-color: $offcanvas-nav-cat-hover-background;
              text-decoration: none;
            }
          }

          li {
            &:last-child {
              border: none;
            }
          }
        }
      }
    }

    .nav__links--primary {
      .sub__navigation {
        display: none;
        background: $nav-subcat-background;
        position: absolute;
        z-index: 1000;
        left: 0;
        border: 1px solid $nav-border-color;
        border-top: 0;
        padding: 0 10px;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      }

      .sub-navigation-section {
        position: static;
        padding: 10px;
      }
    }
  }

  .navigation--middle {
    border-top: 1px solid $nav-border-color;

    .nav__right {
      padding-right: 20px;

      ul.nav__links--shop_info {
        padding-left: 10px;

        .nav-location {
          padding: 0 30px;
        }
      }
    }

    .nav__left {
      padding-left: 30px;
      padding-right: 30px;

      .site-search {
        padding-left: 0;

        .input-group {
          width: 445px;
        }
      }
    }
  }
}

@media (max-width: $screenMdBigMax) {
  .navigation--bottom {
    .nav__links--primary {
      .sub-navigation-section {
        .title {
          color: $offcanvas-nav-cat-title-color;
        }
      }
    }
  }
}

@media (max-width: $screenSm) {
  .navigation--middle {
    .nav__right {
      ul.nav__links--shop_info {
        float: right;

        .componentContainer {
          .yCmsComponent {
            display: inline;
          }
        }

        .nav-location {
          float: right;
          padding: 0 10px;
        }
      }
    }

    .nav-cart {
      padding-left: 30px;

      .mini-cart-price {
        overflow: hidden;
        margin-left: 5px;
        margin-right: 5px;
        float: right;
        font-size: 16px;
        font-weight: 600;
        position: relative;
        top: 4px;
      }

      .mini-cart-link {
        line-height: 55px;
        white-space: nowrap;
      }

      .mini-cart-count {
        &:before {
          content: "(";
          position: relative;
          top: 4px;
        }

        &:after {
          content: ")";
          position: relative;
          top: 4px;
        }
      }

      .mini-cart-icon {
        float: right;
        position: relative;
        top: 5px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .navigation--bottom {
    a {
      padding: 20px 32px;
    }
  }
}

@media (min-width: $screenSm) and (max-width: $screenSmMax) {
  .navigation--middle {
    .nav-cart {
      .mini-cart-icon {
        .glyphicon-shopping-cart {
          margin-left: 5px;
        }
      }
    }

    .nav__left {
      .site-search {
        margin-left: -10px;
      }
    }
  }
}
