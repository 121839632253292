/* Here are all the custom CSS elements related to the "jQuery UI" library. */

/* jQuery UI Datepicker */
.ui-datepicker {
  border: 1px solid $border-color-2;
  background: #ffffff;
  color: $text-color;

  th {
    font-weight: 400;
  }

  .ui-datepicker-header {
    color: $text-color;
    background-color: transparent;
    border: none;
    font-weight: bold;
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    color: $link-color;
    cursor: pointer;
    font-family: 'Glyphicons Halflings', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    height: 15px;
    line-height: 1;
    margin-top: 5px;
    width: 30px;
  }

  .ui-datepicker-prev {
    left: 5px;
    float: left;
    text-align: left;
    @extend .glyphicon-chevron-left;
  }

  .ui-datepicker-next {
    right: 5px;
    float: right;
    text-align: right;
    @extend .glyphicon-chevron-right;
  }

  .ui-icon {
    display: none;
  }
}

/* jQuery UI Icon */
.ui-icon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* jQuery UI Widget Header */
.ui-widget-header {
  .ui-datepicker-prev-hover,
  .ui-datepicker-next-hover {
    &.ui-state-hover {
      border: 0 none;
      background: transparent;
      color: $link-hover-color;
      top: 2px;
    }
  }
}

/* Interaction states */

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background-color: $table-2n-line-bg;
  text-align: center;
  color: $text-color;
}

.ui-state-default {
  a,
  a:link,
  a:visited {
    color: #454545;
    text-decoration: none;
  }
}

.ui-state-hover,
.ui-state-focus {
  a,
  a:hover,
  a:link,
  a:visited {
    color: #2b2b2b;
    text-decoration: none;
  }
}

.ui-state-active {
  a,
  a:link,
  a:visited {
    color: #ffffff;
    text-decoration: none;
  }
}

/* Interaction Cues */

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background: $datepicker-highlight-bg;
  color: $datepicker-highlight-color;
  border-color: $datepicker-highlight-border;
}

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: #777620;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: $datepicker-active-bg;
  color: $datepicker-active-color;
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid #f1a899;
  background: #fddfdf;
  color: #5f3f3f;
}

.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
  color: #5f3f3f;
}

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #5f3f3f;
}

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold;
}

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter:Alpha(Opacity=70); /* support: IE8 */
  font-weight: normal;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter:Alpha(Opacity=35); /* support: IE8 */
  background-image: none;
}

.ui-state-disabled .ui-icon {
  filter:Alpha(Opacity=35); /* support: IE8 - See #6059 */
}

/* Others */

.ui-helper-hidden-accessible {
  @extend .sr-only;
}
