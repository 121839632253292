/* "Skip to" anchors for screen readers (extending .sr-only from Bootstrap) */
.skip, .skiptocontent, .skiptonavigation {
  @extend .sr-only;
}

/* Top Header */
/* Added .container-fluid in order to override Bootstrap's defaults */
.container-fluid {
  &.mc-top-header {
    text-align: center;
    padding: 5px;
    color: #fff;
    font-size: 12px;
  }
}

.countdown a {
    text-align: center;
	  color: #000000;
	  font-weight: bold;
    font-size: 13px;
    .day{
      color:#FF0000;
    }
}

/* Header */
header {
  background: $header-background-color;
  margin-bottom: 0;
}

/* Container for the icons on the top right (Account, Login, Wishlist, MiniCart, etc...) */
.mc-icon-container {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: flex-end;

  .yCmsComponent {
    .icon-wishlist {
      color: rgb(48, 48, 48);
    }
  }

  .yCmsComponent {
    .icon-wishlist {
      color: rgb(48, 48, 48);
    }
  }
}

.nav-cart, .mc-nav-user, .mc-nav-wish {
  line-height: 2;
  margin-top: 5px;
}

.mc-nav-user, .mc-nav-wish {
  a {
    color: #2f353b;
    font-size: 22px;

    &:hover {
      color: $brand-primary;
    }
  }
}

.mini-cart-count, .mini-wish-count {
  font-weight: 700;
  font-size: 12px;
}

.mini-cart-count, .mini-wish-count {
  line-height: 0;
  text-align: center;
}

.mini-nav-user-count {
  font-weight: 700;
  font-size: 12px;
  color: red;
}

.mini-nav-user-count {
  line-height: 0;
  margin-left:6px;
}




/* Mini Cart Popup */
.mini-cart {
  .mini-cart-body {
    @extend .clearfix;

    .legend {
      margin-bottom: ($grid-gutter-width / 2);

      a {
        float: right;
      }
    }

    .mini-cart-list {
      @extend .list-unstyled;

      li + li {
        margin-top: ($grid-gutter-width / 2);
      }

      .mini-cart-item {
        .thumb {
          float: left;
          margin-right: ($grid-gutter-width / 2);

          img {
            max-width: 65px;
          }
        }

        .details {
          margin-left: 65px+($grid-gutter-width / 2);

          .name {
            font-weight: bold;
            color: inherit;
          }
        }

        .price {
          text-align: right;
          font-weight: bold;
        }
      }
    }

    .mini-cart-totals {
      border: 1px solid $border-color-2;
      border-width: 1px 0;
      margin: ($grid-gutter-width / 2) 0 ($grid-gutter-width + $grid-gutter-width / 2);
      padding: ($grid-gutter-width / 2);

      .key {
        float: left;
      }

      .value {
        text-align: right;
      }
    }

    .mini-cart-checkout-button {
      margin: ($grid-gutter-width / 2) 0;
    }

    & img {
      width: 100%;
    }
  }
}

/* Login link at the top of the page on desktop */
.login-link {
  margin-right: 20px;
  padding-top: 16px;
  width: 100%;

  .nav-mc-login-links__child-wrap {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: flex-start;

    a {
      color: $text-color;
      float: left;
      margin-right: 12px;
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.mc-login-link {
  margin-left: -10px;
}


/* MiniCart slot in the top right */
.miniCartSlot {
  > div[data-ng-transclude] {
    display: inline-block;
  }
}

.nav-cart {
  a:hover {
    color: $brand-primary;
  }
}

/* User slot in the top right */
.mc-nav-user {
  margin-bottom: -10px;
  margin-left: 9px;
  margin-right: 10px;
  min-width: 50px
}

/* Wishlist slot in the top right */
.mc-nav-wish {
  text-align: center;
  margin-right: 40px;

  a {
    .mini-wish-count {
      color: $mc-orange;
    }
  }
}

/* Barcode */
.barcode-popup-content {
  display: none;
}

.barcode-overlay {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 1px;
  display: none;
}

.barcode-popup-btn-cont {
  text-align: center;

  button {
    min-width: 220px;
    margin-top: 20px;
  }
}

/* Media Queries */

@media (max-width: $screenXs) {
  header.mcheader {
    padding-top: 5px;
    margin-bottom: 10px !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 101;
  }

  .head-cont {
    transition: .2s linear;
  }
  .customer-detail-bg {
    background-color: #eee;
  }
  .mc-logo {
    .banner__component {
      width: 80%;

      a {
        > img {
          max-width: 110px;
          padding-top: 10px;
        }
      }
    }
  }

  .mc-mobile-toolbar-offscreen {
    transform: translate3d(0, -100%, 0);
  }

  div.mc-minicart-cont {
    position: absolute;
    right: 0;
  }

  .mc-icon-container {
    .yCmsComponent {
      .mc-nav-user {
        margin-right: 15px;

        a {
          span {
            font-size: 24px;
          }
        }
      }

      .mc-nav-wish {
        margin-right: 15px;

        a {
          .icon-wishlist {
            font-size: 24px;
            padding-top: 5px;
          }
        }
      }
      .nav-cart {
        margin-right: 15px;

        a {
          .mini-cart-icon {
            span {
              font-size: 24px;
              padding-top: 5px;
            }
          }

          .mini-cart-count {
            span {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $screenXXl) {
  .mc-first-head {
    padding-bottom: 5px;
  }

  .mc-login-link {
    padding-top: 25px !important;
  }

  .mc-logo {
    max-width: 320px !important;
  }

  .nav__links--account {
    font-size: 14px !important;
  }

  .mc-nav__right {
    .nav-mc-top-links {
      .nav-mc-top-links__child-wrap {
        a {
          font-size: 15px !important;
        }
      }
    }
  }

  .mc-nav-user {
    margin-right: 20px !important;
  }

  .mc-nav-user a, .mc-nav-wish a, .mini-cart-icon {
    font-size: 32px !important;
  }

  .mini-cart-count, .mini-wish-count, .mini-nav-user-count{
    font-size: 15px !important;
  }

  .mc-nav__right .nav-mc-top-links {
    .nav-mc-top-links__child-wrap {
      padding-top: 6px !important;
    }
  }
}

@media (min-width: $screenXl) and (max-width: $screenXlMax) {
  .mc-logo {
    max-width: 280px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (min-width: $screenLg) and (max-width: $screenLgMax) {
  .mc-logo {
    max-width: 280px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (min-width: $screenMdBig) and (max-width: $screenMdBigMax) {
  .mc-logo {
    max-width: 250px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (min-width: $screenSmBig) and (max-width: $screenMd) {
  .mc-logo {
    .banner__component {
      img {
        max-width: 260px;
        padding: 8px 23px;
      }
    }
  }

  .mc-first-head {
    min-height: 50px;
  }

  .mc-minicart-cont {
    position: absolute !important;
    top: 70px;
    right: 25px;
  }
}

@media (min-width: $screenMd) {
  .nav-mc-top-links__child-wrap div:last-child a {
    margin-right: 0;
  }
}

@media (min-width: $screenMd) {
  header {
    &.mcheader {
      padding-top: 15px;
      padding-bottom: 5px;
      position: sticky;
      top: 0;
      z-index: 1000;
    }
  }
}
